import axios from 'axios';
import { API_URL } from '../constants.js';
export const call = async (method, url, params, data, type) => {
  const accessToken = localStorage.getItem('accessToken');
  let contentType = type && type === "image" ? "multipart/form-data" : "application/json"
  const result = await axios({
    method,
    url: API_URL + url,
    params,
    data: { ...data },
    headers: {
      'Content-Type': contentType,
      'Accept-Language': 'en',
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    },
    // timeout: 60000,
    responseType: 'json'
  });

  return result;
  // }
  // return null
};
