import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import CreateIcon from '@mui/icons-material/Create';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: "capitalize" } }
  },
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #D1DFFF'
  },
  '& .MuiTableRow-root:nth-child(even)': {
    backgroundColor: '#f2f2f2', // Alternate row color
  },
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: '15px',
  fontWeight: 600,
  color: 'white',
  backgroundColor: "rgba(39, 75, 175, 0.8)",
  '&:first-of-type': {
    borderTopLeftRadius: '15px', // Curved edge on the top-left corner
  },
  '&:last-of-type': {
    borderTopRightRadius: '15px', // Curved edge on the top-right corner
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`,
  autoCapitalize: "none"

}));

const PaginationTable = ({
  data,
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSkipData,
  setSortData,
  sortData,

}) => {
  const restricted = ["help_center", "explore", "blogs", "home", "membership", "sidebox"]
  const navigate = useNavigate()
  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };

  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableHead align="left" style={{ paddingLeft: "50px" }}>Page Name</StyledTableHead>
            <StyledTableHead align="center">
              Update Date
              {sortData === 'desc' ? (
                <IconButton onClick={() => setSortData('asc')}>
                  <Icon sx={{ color: '#01052D' }}>
                    <Tooltip title="Sort Date">
                      <ArrowDownwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              ) : (
                <IconButton onClick={() => setSortData('desc')}>
                  <Icon sx={{ color: 'white' }}>
                    <Tooltip title="Sort Date">
                      <ArrowUpwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              )}
            </StyledTableHead>
            <StyledTableHead align="center">Action</StyledTableHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((user, index) => {
            let title = user.key.split("_").join(" ")
            return (
              <TableRow key={index}>
                <StyledTableCell align="left" style={{ paddingLeft: "50px" }}>
                  {title}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {moment(user.updatedAt).format('ll')}
                </StyledTableCell>
                <StyledTableCell align="center">
                  <IconButton onClick={() => navigate(`/content-management/edit-page/${user.key}/${user.id}`)}>
                    <Tooltip title="Edit">
                      <CreateIcon />
                    </Tooltip>
                  </IconButton>
                </StyledTableCell>
              </TableRow>
            )
          }
          )}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalItems}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default PaginationTable;
