import * as React from 'react';
import axios from 'axios';
import { API_URL } from 'app/constants';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTheme, TextField, Grid, InputLabel, TextareaAutosize } from '@mui/material';
import { useState } from 'react';
import { call } from '../../utils/apiCall';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import ImageVerify from 'app/utils/imageVerify';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor'

const H2 = styled('h2')(({ theme }) => ({
    fontSize: '24px',
    fontWeight: '600',
    color: '01052D',
    marginTop: '0px',
    marginBottom: '30px'
}));

// class MyUploadAdapter {
//     constructor(loader) {
//         this.loader = loader;
//     }

//     async upload() {
//         const data = new FormData();
//         const file = await this.loader.file;
//         data.append('upload', file);

//         try {
//             const response = await axios.post('http://localhost:3005/api/v1/admin/upload-content', data, {
//                 headers: {
//                     'Accept-Language': 'en-US,en;q=0.9',
//                     'Content-Type': 'multipart/form-data'
//                 }
//             });

//             return {
//                 default: response.data.url
//             };
//         } catch (error) {
//             console.error('Error uploading image:', error);
//             throw error;
//         }
//     }

//     abort() {
//         // Handle abort
//     }
// }

// // Plugin to integrate the custom upload adapter with CKEditor
// function MyCustomUploadAdapterPlugin(editor) {
//     editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
//         return new MyUploadAdapter(loader);
//     };
// }


export default function EditPage(props) {
    const richTextPage = ['about_us', 'community_guidelines']
    let textRegEx = /^(?!\s)(.{0,250})$/;
    const { toggle } = props;
    const { palette } = useTheme();
    const textError = palette.error.main;
    const params = useParams()
    const [pageData, setPageData] = useState([])
    const [title, setTitle] = useState("")
    const [titleError, setTitleError] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [subTitleError, setSubTitleError] = useState("")
    const [currentTitle, setCurrentTitle] = useState("")
    const [currentTitleError, setCurrentTitleError] = useState("")
    const [currentText, setCurrentText] = useState("")
    const [currentTextError, setCurrentTextError] = useState("")
    const [idsToDelete, setIdsToDelete] = useState([])
    const [imageUrl, setImageUrl] = useState("")
    const [linkError, setLinkError] = useState("")
    const [link, setLink] = useState("")
    const [emptyTextId, setEmptyTextId] = useState([])
    const [invalidTextId, setInvalidTextId] = useState([])
    const [emptyTextError, setEmptyTextError] = useState("Sub-text is required.")
    const [invalidTextError, setInvalidTextError] = useState("Please enter valid sub-text upto 250 characters.")
    const navigate = useNavigate()


    const handleSubmit = async () => {
        let formValid = true

        if (title == "") {
            setTitleError("Title is required")
            formValid = false
        } else if (!textRegEx.test(title)) {
            setTitleError("Please enter valid title upto 250 characaters")
            formValid = false
        }

        if (subTitle == "") {
            setSubTitleError("Text is required")
            formValid = false
        } else if (!textRegEx.test(subTitle)) {
            setSubTitleError("Please enter valid text upto 250 characaters")
            formValid = false
        }

        if (params.key == "sidebox" && link == "") {
            setLinkError("Link is required.")
            formValid = false
        }

        if (params.key !== "workerpool" && params.key !== "about_us" && pageData.length > 0) {
            for (let i = 0; i < pageData.length; i++) {
                if (pageData[i].body == "") {
                    formValid = false
                    setEmptyTextId(prevData => {
                        const updatedData = [...prevData];
                        updatedData.push(i);
                        return updatedData;
                    });
                }
                // else if (!textRegEx.test(pageData[i].body)) {
                //     setInvalidTextId(prevData => {
                //         const updatedData = [...prevData];
                //         updatedData.push(i);
                //         return updatedData;
                //     });
                //     formValid = false
                // }
            }
        }

        if (formValid) {
            let newData
            if (params.key === "workerpool") {
                newData = []
                for (let i = 0; i < pageData.length; i++) {
                    if (!pageData[i].id) {
                        pageData[i]["parent_id"] = params.id
                        newData.push(pageData[i])
                    }
                }
            } else {
                newData = pageData
            }


            let bodyData = {
                mainTitle: title,
                mainText: subTitle,
                mainId: parseInt(params.id),
                newData: newData,
                idsToDelete: idsToDelete,
                forParam: params.key
            }

            if (imageUrl !== "") {
                bodyData["media"] = imageUrl
            } else {
                bodyData["media"] = null
            }

            if (params.key == "sidebox") {
                bodyData["link"] = link
            } else {
                bodyData["link"] = null
            }

            try {
                const res = await call(
                    'patch',
                    'api/v1/admin/edit-content',
                    {},
                    bodyData
                )

                if (res.data.success === true) {
                    navigate("/content-management")
                    toast.success("Content updated successfully.", { position: toast.POSITION.BOTTOM_LEFT })
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleImageChange = async (e, index = null) => {
        if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/png") {
            try {
                let formData = new FormData()
                formData.append("image", e.target.files[0])
                const res = await axios.post(`${API_URL}api/v1/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept-Language': 'en'
                    }
                })
                // const res = await call('post', 'api/v1/upload', null, formData, "image");
                // if (res.data.success === true) {
                //   console.log(res)
                // }

                if (res.data.success === true) {
                    let myImage = res.data.urlsArray[0].split(".com/")[1]
                    const newImage = API_URL + myImage
                    if (index !== null) {
                        const updateItems = [...pageData]
                        updateItems[index].media = newImage
                        setPageData(updateItems)
                    } else {
                        setImageUrl(newImage)
                    }

                }

            } catch (err) {
                console.log(err);
            }
        }
    }

    const removeOldData = (oldId, index) => {
        console.log(pageData)
        if (pageData.length == 1) {
            toast.error("There should be atleast one row in body section.", { position: toast.POSITION.BOTTOM_LEFT })
        } else {
            setIdsToDelete(prevData => {
                const updatedData = [...prevData];
                updatedData.push(oldId);
                return updatedData;
            });

            setPageData(prevData => {
                const updatedData = [...prevData];
                updatedData.splice(index, 1);
                return updatedData;
            })
        }

    }

    const removeNewData = (index) => {
        if (pageData.length == 1) {
            toast.error("There should be atleast one row in body section.", { position: toast.POSITION.BOTTOM_LEFT })
        } else {
            setPageData(prevData => {
                const updatedData = [...prevData];
                updatedData.splice(index, 1);
                return updatedData;
            })
        }

    }

    const handleNewData = () => {

        let formValid = true

        if (currentTitle === "") {
            setCurrentTitleError("Sub-title is required.")
            formValid = false
        } else if (!textRegEx.test(currentTitle)) {
            setCurrentTitleError("Please enter valid sub-title upto 50 characaters")
            formValid = false
        }

        if (currentText === "") {
            setCurrentTextError("Sub-text is required.")
            formValid = false
        } else if (!textRegEx.test(currentText)) {
            setCurrentTextError("Please enter valid sub-text upto 250 characaters")
            formValid = false
        }

        if (formValid) {
            let key = currentTitle.split(" ").join("-").toLocaleLowerCase()

            let myObj = {
                body: currentText,
                key: key,
                link: null,
                media: null,
                router_link: null,
                title: currentTitle
            }

            setPageData(prevData => {
                const updatedData = [...prevData];
                updatedData.push(myObj);
                return updatedData;
            });

            setCurrentText("")
            setCurrentTitle("")
        }

    }

    const handleBodyChange = (text, index, type = "body") => {
        if (pageData && pageData.length > 0) {
            const updateItems = [...pageData]
            if (type == "body") {
                updateItems[index].body = text
            } else {
                updateItems[index].title = text
            }
            setPageData(updateItems)
        }

    }

    const getPageData = () => {
        (async () => {
            try {
                const res = await call(
                    'get',
                    'api/v1/get-static-pages',
                    { title: params.key },
                    null
                );
                if (res.data.success) {
                    setTitle(res.data.data.title)
                    setSubTitle(res.data.data.subtitle)

                    if (params.key == "home") {
                        setPageData([])
                    } else {
                        setPageData(res.data.data.body)
                    }

                    if (params.key == "membership" || params.key == "sidebox") {
                        setImageUrl(res.data.data.media)
                    }

                    if (params.key == "sidebox") {
                        setLink(res.data.data.link)
                    }

                }

            } catch (err) {
                console.log(err);
                setPageData([]);
            }
        })();
    }

    useEffect(() => {
        getPageData()
    }, [])


    return (
        <form style={{ padding: "30px" }}>
            <h3 className="breadcumbTitle" style={{}}>
                <span className='bread-link' onClick={() => navigate("/content-management")} onMouseEnter={(e) => e.target.style.color = '#2563a4'} onMouseLeave={(e) => e.target.style.color = 'black'}> Manage Content {'>'} </span>
                <span> Edit Content </span>
            </h3>
            <H2>Edit Content - {params.key.split("_").join(" ").toUpperCase()}</H2>
            <h2>Header</h2>
            <Grid container xs={6} lg={6}>
                <Grid item xs="12">
                    <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="Title"
                        label="Title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        onClick={() => setTitleError("")}
                        helperText={titleError}
                        error={Boolean(titleError)}
                        variant="outlined"
                        sx={{ mb: 3 }}
                    />
                </Grid>

                <Grid item xs="12">
                    <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="Sub-Title"
                        label="Sub-Title"
                        value={subTitle}
                        onChange={(e) => setSubTitle(e.target.value)}
                        onClick={() => setSubTitleError("")}
                        helperText={subTitleError}
                        error={Boolean(subTitleError)}
                        variant="outlined"
                        sx={{ mb: 3 }}
                    />
                </Grid>
            </Grid>





            <Grid container spacing={3} width={800}>

                <Grid item>
                    {params.key == "membership" || params.key == "sidebox" ?
                        <>
                            <InputLabel>Background Image</InputLabel>
                            <div style={{ display: "flex", flexDirection: "column", width: "200px", alignItems: "center", justifyContent: "center" }}>
                                <ImageVerify imageURL={imageUrl && imageUrl !== "" ? imageUrl : null} width={"100%"} height={"150px"}></ImageVerify>
                                <Button component='label'>Upload <input type='file' hidden accept="image/png, image/jpeg" onChange={(e) => handleImageChange(e)}></input></Button>
                            </div>
                        </> : <></>}

                    {
                        params.key == "sidebox" ?
                            <TextField
                                fullWidth
                                size="small"
                                type="text"
                                name="Link"
                                label="Link"
                                variant="outlined"
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                onClick={() => setLinkError("")}
                                helperText={linkError}
                                error={Boolean(linkError)}
                                sx={{ mt: 3 }}
                            /> : <></>
                    }


                    {
                        pageData && pageData.length > 0 && params.key === "workerpool" ?
                            <>
                                <h2>Body</h2>
                                <Grid container spacing={1}>
                                    {
                                        pageData && pageData.length > 0 ?
                                            pageData.map((ele, index) => {
                                                return (
                                                    <>
                                                        <Grid item lg={5} md={6} sm={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="Sub-Title"
                                                                label="Sub-Title"
                                                                variant="outlined"
                                                                value={ele.title}
                                                                disabled
                                                                sx={{ mb: 3, mt: 2 }}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={5} md={6} sm={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="Sub-Text"
                                                                label="Sub-Text"
                                                                variant="outlined"
                                                                value={ele.body}
                                                                disabled
                                                                onChange={(e) => handleBodyChange(e.target.value, index)}
                                                                sx={{ mb: 3, mt: 2 }}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={2} md={6} sm={6} xs={12}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                sx={{ mt: 2, backgroundColor: '#0432A3' }}
                                                                onClick={() => ele.id ? removeOldData(ele.id, index) : removeNewData(index)}
                                                            >
                                                                -
                                                            </Button>
                                                        </Grid>
                                                    </>
                                                )
                                            })
                                            :
                                            <></>
                                    }
                                    <Grid item lg={5} md={6} sm={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="Sub-Title"
                                            label="Sub-Title"
                                            variant="outlined"
                                            value={currentTitle}
                                            onClick={() => setCurrentTitleError("")}
                                            helperText={currentTitleError}
                                            error={Boolean(currentTitleError)}
                                            onChange={(e) => setCurrentTitle(e.target.value)}
                                            sx={{ mb: 3, mt: 2 }}
                                        />
                                    </Grid>
                                    <Grid item lg={5} md={6} sm={6} xs={12}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            type="text"
                                            name="Sub-Text"
                                            label="Sub-Text"
                                            variant="outlined"
                                            value={currentText}
                                            onChange={(e) => setCurrentText(e.target.value)}
                                            onClick={() => setCurrentTextError("")}
                                            helperText={currentTextError}
                                            error={Boolean(currentTextError)}
                                            sx={{ mb: 3, mt: 2 }}
                                        />
                                    </Grid>
                                    <Grid item lg={2} md={6} sm={6} xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleNewData()}
                                            sx={{ mt: 2, backgroundColor: '#0432A3' }}
                                        >
                                            +
                                        </Button>
                                    </Grid>
                                </Grid>
                            </> :
                            <></>
                    }

                    {
                        pageData && pageData.length > 0 && params.key !== "workerpool" && params.key !== "legal_terms" && !richTextPage.includes(params.key) ?
                            <>
                                <h2>Body</h2>
                                <Grid container spacing={1}>
                                    {
                                        pageData && pageData.length > 0 ?
                                            pageData.map((ele, index) => {
                                                return (
                                                    <>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="Sub-Title"
                                                                label="Sub-Title"
                                                                variant="outlined"
                                                                value={ele.title}
                                                                disabled
                                                                sx={{ mb: 3, mt: 2 }}
                                                            />
                                                        </Grid>
                                                        <Grid item lg={6} md={6} sm={6} xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                type="text"
                                                                name="Sub-Text"
                                                                label="Sub-Text"
                                                                variant="outlined"
                                                                value={ele.body}
                                                                onChange={(e) => handleBodyChange(e.target.value, index)}
                                                                onClick={() => {
                                                                    setEmptyTextId(prevData => {
                                                                        const updatedData = prevData.filter(item => item !== index);
                                                                        return updatedData;
                                                                    })
                                                                    setInvalidTextId(prevData => {
                                                                        const updatedData = prevData.filter(item => item !== index);
                                                                        return updatedData;
                                                                    })
                                                                }}
                                                                helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                                error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                                sx={{ mb: 3, mt: 2 }}
                                                            />
                                                        </Grid>

                                                    </>
                                                )
                                            })
                                            :
                                            <></>
                                    }
                                </Grid>
                            </> :
                            <></>
                    }
                </Grid>

            </Grid>

            {params.key == "community_guidelines" ?
                <>
                    <h2>Body</h2>
                    <Grid container xs={12}>
                        <Grid item xs={12}>
                            <CKEditor
                                editor={Editor}
                                data={pageData && pageData.length > 0 ? pageData[0].body : ""}
                                onReady={editor => {
                                    editor.editing.view.change((writer) => {
                                        writer.setStyle(
                                            "width",
                                            "100%",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                    // You can store the "editor" and use when it is needed.
                                    console.log('Editor is ready to use!', editor);
                                }}
                                onChange={(event, editor) => {
                                    handleBodyChange(editor.getData(), 0)
                                }}
                                onBlur={(event, editor) => {
                                    console.log('Blur.', editor);
                                }}
                                onFocus={(event, editor) => {
                                    console.log('Focus.', editor);
                                }}

                                onInit={(editor) => {
                                    editor.editing.view.change((writer) => {
                                        writer.setStyle(
                                            "height",
                                            "200px",
                                            editor.editing.view.document.getRoot()
                                        );
                                    });
                                }}
                            />
                        </Grid>
                    </Grid>
                </> :
                params.key == "legal_terms" ?
                    <>
                        <h2>Body</h2>
                        {pageData && pageData.length > 0 ?
                            pageData.map((ele, index) => {
                                return (
                                    <Grid container xs={12} style={{ backgroundColor: "#f0f0f0", borderRadius: "8px", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)", padding: "20px 20px 20px 20px", marginBottom: "25px" }}>
                                        <Grid item xs={12}>
                                            <h3>{ele.title}</h3>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CKEditor
                                                editor={Editor}
                                                data={pageData && pageData.length > 0 ? pageData[index].body : ""}
                                                onReady={editor => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            "width",
                                                            "100%",
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                    // You can store the "editor" and use when it is needed.
                                                    console.log('Editor is ready to use!', editor);
                                                }}
                                                onChange={(event, editor) => {
                                                    handleBodyChange(editor.getData(), index)
                                                }}
                                                onBlur={(event, editor) => {
                                                    console.log('Blur.', editor);
                                                }}
                                                onFocus={(event, editor) => {
                                                    console.log('Focus.', editor);
                                                }}

                                                onInit={(editor) => {
                                                    editor.editing.view.change((writer) => {
                                                        writer.setStyle(
                                                            "height",
                                                            "200px",
                                                            editor.editing.view.document.getRoot()
                                                        );
                                                    });
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                )
                            })
                            : <></>}

                    </> :
                    params.key == "about_us" ?
                        <>
                            <h2>Body</h2>
                            <Grid container xs={6} lg={12}>
                                <Grid item xs={12}>
                                    <h3>Heading 1</h3>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        variant="outlined"
                                        value={pageData[2]?.title}
                                        onChange={(e) => handleBodyChange(e.target.value, 2, "title")}
                                        // onClick={() => {
                                        //     setEmptyTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        //     setInvalidTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        // }}
                                        // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                        // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                        sx={{ mb: 3 }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <h3>Text-1</h3>
                                    <TextareaAutosize
                                        minRows={3}
                                        style={{ width: "100%", font: "inherit", padding: "10px", marginBottom: "20px" }}
                                        variant="outlined"
                                        value={pageData[2]?.body}
                                        onChange={(e) => handleBodyChange(e.target.value, 2, "body")}
                                    // onClick={() => {
                                    //     setEmptyTextId(prevData => {
                                    //         const updatedData = prevData.filter(item => item !== index);
                                    //         return updatedData;
                                    //     })
                                    //     setInvalidTextId(prevData => {
                                    //         const updatedData = prevData.filter(item => item !== index);
                                    //         return updatedData;
                                    //     })
                                    // }}
                                    // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                    // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <h3>Heading 2</h3>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        variant="outlined"
                                        value={pageData[3]?.title}
                                        onChange={(e) => handleBodyChange(e.target.value, 3, "title")}
                                        // onClick={() => {
                                        //     setEmptyTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        //     setInvalidTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        // }}
                                        // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                        // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                        sx={{ mb: 3 }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <h3>Text-2</h3>
                                    <TextareaAutosize
                                        minRows={3}
                                        style={{ width: "100%", font: "inherit", padding: "10px", marginBottom: "20px" }}
                                        variant="outlined"
                                        value={pageData[3]?.body}
                                        onChange={(e) => handleBodyChange(e.target.value, 3, "body")}
                                    // onClick={() => {
                                    //     setEmptyTextId(prevData => {
                                    //         const updatedData = prevData.filter(item => item !== index);
                                    //         return updatedData;
                                    //     })
                                    //     setInvalidTextId(prevData => {
                                    //         const updatedData = prevData.filter(item => item !== index);
                                    //         return updatedData;
                                    //     })
                                    // }}
                                    // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                    // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <h3>Stats Box</h3>
                                    <Grid container rowGap={3} sx={{ justifyContent: "space-evenly" }}>
                                        <Grid item xs={5} sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex", backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px" }}>
                                            <TextField
                                                size="small"
                                                type="text"
                                                name="Stat-1"
                                                label="Stat-1"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[4]?.title : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 4, "title")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3, width: "100px" }}
                                            />
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="Stat-1-Description"
                                                label="Stat-1-Description"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[4]?.body : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 4, "body")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3 }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex", backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px" }}>
                                            <TextField
                                                size="small"
                                                type="text"
                                                name="Stat-2"
                                                label="Stat-2"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[5]?.title : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 5, "title")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3, width: "100px" }}
                                            />
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="Stat-2-Description"
                                                label="Stat-2-Description"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[5]?.body : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 5, "body")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3 }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex", backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px" }}>
                                            <TextField
                                                size="small"
                                                type="text"
                                                name="Stat-3"
                                                label="Stat-3"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[6]?.title : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 6, "title")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3, width: "100px" }}
                                            />
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="Stat-3-Description"
                                                label="Stat-3-Description"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[6]?.body : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 6, "body")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3 }}
                                            />
                                        </Grid>
                                        <Grid item xs={5} sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex", backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px" }}>
                                            <TextField
                                                size="small"
                                                type="text"
                                                name="Stat-4"
                                                label="Stat-4"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[7]?.title : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 7, "title")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3, width: "100px" }}
                                            />
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="Stat-4-Description"
                                                label="Stat-4-Description"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[7]?.body : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 7, "body")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3 }}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <h3>Heading 3</h3>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        variant="outlined"
                                        value={pageData[8]?.title}
                                        onChange={(e) => handleBodyChange(e.target.value, 8, "title")}
                                        // onClick={() => {
                                        //     setEmptyTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        //     setInvalidTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        // }}
                                        // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                        // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                        sx={{ mb: 3 }}
                                    />
                                </Grid>

                                <Grid item xs={12} sx={{ backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px", mb: 5 }}>
                                    <h3>Sub-Heading-1</h3>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        variant="outlined"
                                        value={pageData[0]?.title}
                                        onChange={(e) => handleBodyChange(e.target.value, 0, "title")}
                                        // onClick={() => {
                                        //     setEmptyTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        //     setInvalidTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        // }}
                                        // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                        // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                        sx={{ mb: 3 }}
                                    />
                                    <h3>Sub-Text-1</h3>
                                    <TextareaAutosize
                                        minRows={3}
                                        style={{ width: "100%", font: "inherit", padding: "10px", marginBottom: "20px" }}

                                        // name="Heading-1"
                                        // label="Heading-1"
                                        variant="outlined"
                                        value={pageData[0]?.body}
                                        onChange={(e) => handleBodyChange(e.target.value, 0, "body")}
                                    // onClick={() => {
                                    //     setEmptyTextId(prevData => {
                                    //         const updatedData = prevData.filter(item => item !== index);
                                    //         return updatedData;
                                    //     })
                                    //     setInvalidTextId(prevData => {
                                    //         const updatedData = prevData.filter(item => item !== index);
                                    //         return updatedData;
                                    //     })
                                    // }}
                                    // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                    // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                    />

                                    <h3>Image-1</h3>
                                    <div style={{ display: "flex", flexDirection: "column", width: "200px", alignItems: "center", justifyContent: "center" }}>
                                        <ImageVerify imageURL={pageData.length > 0 && pageData[0]?.media && pageData[0]?.media !== "" ? pageData[0]?.media : null} width={"100%"} height={"150px"}></ImageVerify>
                                        <Button component='label'>Upload <input type='file' hidden accept="image/png, image/jpeg" onChange={(e) => handleImageChange(e, 0)}></input></Button>
                                    </div>


                                </Grid>

                                <Grid item xs={12} sx={{ backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px" }}>
                                    <h3>Sub-Heading-2</h3>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        // name="Heading-1"
                                        // label="Heading-1"
                                        variant="outlined"
                                        value={pageData[1]?.title}
                                        onChange={(e) => handleBodyChange(e.target.value, 1, "title")}
                                        // onClick={() => {
                                        //     setEmptyTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        //     setInvalidTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        // }}
                                        // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                        // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                        sx={{ mb: 3 }}
                                    />
                                    <h3>Sub-Text-2</h3>
                                    <TextareaAutosize
                                        minRows={3}
                                        style={{ width: "100%", font: "inherit", padding: "10px", marginBottom: "20px" }}

                                        // name="Heading-1"
                                        // label="Heading-1"
                                        variant="outlined"
                                        value={pageData[1]?.body}
                                        onChange={(e) => handleBodyChange(e.target.value, 1, "body")}
                                    // onClick={() => {
                                    //     setEmptyTextId(prevData => {
                                    //         const updatedData = prevData.filter(item => item !== index);
                                    //         return updatedData;
                                    //     })
                                    //     setInvalidTextId(prevData => {
                                    //         const updatedData = prevData.filter(item => item !== index);
                                    //         return updatedData;
                                    //     })
                                    // }}
                                    // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                    // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                    />

                                    <h3>Image-2</h3>
                                    <div style={{ display: "flex", flexDirection: "column", width: "200px", alignItems: "center", justifyContent: "center" }}>
                                        <ImageVerify imageURL={pageData.length > 0 && pageData[1]?.media && pageData[1]?.media !== "" ? pageData[1]?.media : null} width={"100%"} height={"150px"}></ImageVerify>
                                        <Button component='label'>Upload <input type='file' hidden accept="image/png, image/jpeg" onChange={(e) => handleImageChange(e, 1)}></input></Button>
                                    </div>


                                </Grid>

                                <Grid item xs={12} sx={{ mt: 3 }}>
                                    <h3>Heading 4</h3>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        // name="Heading-1"
                                        // label="Heading-1"
                                        variant="outlined"
                                        value={pageData[9]?.title}
                                        onChange={(e) => handleBodyChange(e.target.value, 9, "title")}
                                        // onClick={() => {
                                        //     setEmptyTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        //     setInvalidTextId(prevData => {
                                        //         const updatedData = prevData.filter(item => item !== index);
                                        //         return updatedData;
                                        //     })
                                        // }}
                                        // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                        // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                        sx={{ mb: 3 }}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    <h3>Timeline Box</h3>
                                    <Grid container sx={{ justifyContent: "space-around" }}>
                                        <Grid item xs={2.9} sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex", backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px" }}>
                                            <TextField
                                                size="small"
                                                type="text"
                                                name="Year-1"
                                                label="Year-1"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[10]?.title : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 10, "title")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3, width: "100px" }}
                                            />
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="Year-1-Description"
                                                label="Year-1-Description"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[10]?.body : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 10, "body")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3 }}
                                            />
                                        </Grid>
                                        <Grid item xs={2.9} sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex", backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px" }}>
                                            <TextField
                                                size="small"
                                                type="text"
                                                name="Year-2"
                                                label="Year-2"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[11]?.title : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 11, "title")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3, width: "100px" }}
                                            />
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="Year-2-Description"
                                                label="Year-2-Description"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[11]?.body : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 11, "body")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3 }}
                                            />
                                        </Grid>
                                        <Grid item xs={2.9} sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex", backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px" }}>
                                            <TextField
                                                size="small"
                                                type="text"
                                                name="Year-3"
                                                label="Year-3"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[12]?.title : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 12, "title")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3, width: "100px" }}
                                            />
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="Year-3-Description"
                                                label="Year-3-Description"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[12]?.body : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 12, "body")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3 }}
                                            />
                                        </Grid>
                                        <Grid item xs={2.9} sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", display: "flex", backgroundColor: "rgb(240, 240, 240)", borderRadius: "8px", boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 20px", padding: "20px" }}>
                                            <TextField
                                                size="small"
                                                type="text"
                                                name="Year-4"
                                                label="Year-4"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[13]?.title : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 13, "title")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3, width: "100px" }}
                                            />
                                            <TextField
                                                fullWidth
                                                size="small"
                                                type="text"
                                                name="Year-4-Description"
                                                label="Year-4-Description"
                                                variant="outlined"
                                                value={pageData.length > 0 ? pageData[13]?.body : ""}
                                                onChange={(e) => handleBodyChange(e.target.value, 13, "body")}
                                                // onClick={() => {
                                                //     setEmptyTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                //     setInvalidTextId(prevData => {
                                                //         const updatedData = prevData.filter(item => item !== index);
                                                //         return updatedData;
                                                //     })
                                                // }}
                                                // helperText={emptyTextId.includes(index) ? emptyTextError : invalidTextId.includes(index) ? invalidTextError : ""}
                                                // error={Boolean(emptyTextId.includes(index) || invalidTextId.includes(index))}
                                                sx={{ mb: 3 }}
                                            />
                                        </Grid>

                                    </Grid>
                                </Grid>

                            </Grid>

                        </>
                        :
                        <></>}

            <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                sx={{ mt: 4, backgroundColor: '#0432A3', width: "160px" }}
            >
                Update Content
            </Button>
        </form>
    );
}
