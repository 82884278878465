import * as React from 'react';
import axios from 'axios';
import { API_URL } from 'app/constants';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { useTheme, TextField, InputAdornment, Grid } from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { call } from '../../utils/apiCall'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    PhoneNumberUtil,
    // using PNF alias to follow along with documentaion
    PhoneNumberFormat as PNF
} from 'google-libphonenumber';
import { useEffect } from 'react';
import ImageVerify from 'app/utils/imageVerify';

const H2 = styled('h2')(({ theme }) => ({
    fontSize: '24px',
    fontWeight: '600',
    color: '01052D',
    marginTop: '0px',
    marginBottom: '30px'
}));

export default function MyAccount() {
    const { palette } = useTheme();
    const textError = palette.error.main;
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneCount, setPhoneCount] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [errorEmail, setErrorEmail] = useState('');
    const [errorFirstName, setErrorFirstName] = useState('');
    const [errorLastName, setErrorLastName] = useState('');
    const [errorPassword, setErrorPassword] = useState('');
    const [errorPhoneNumber, setErrorPhoneNumber] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [rolesData, setRolesData] = useState([])
    const [selectedRole, setSelectedRole] = useState(0)
    const [imageUrl, setImageUrl] = useState("")


    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneCountValidation = () => {
        let num = JSON.stringify(phoneNumber);
        let code = JSON.stringify(phoneCount);
        const number = phoneUtil.parse(num, code);

        //console.log(phoneUtil.isValidNumber(number));
        return phoneUtil.isValidNumber(number);
    };

    const getUserData = () => {
        (async () => {
            try {
                const res = await call(
                    'get',
                    'api/v1/admin/my-account',
                    null,
                    null
                );
                setFirstName(res.data.myData.first_name)
                setLastName(res.data.myData.last_name)
                setEmail(res.data.myData.email)
                setPhoneNumber(res.data.myData.phone)
                setSelectedRole(res.data.myData.role.id)
                setImageUrl(res.data.myData.profile_img)

            } catch (err) {
                console.log(err);
            }
        })();
    }

    const handleImageChange = async (e) => {
        if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/png") {
            try {
                let formData = new FormData()
                formData.append("image", e.target.files[0])
                const res = await axios.post(`${API_URL}api/v1/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept-Language': 'en'
                    }
                })
                // const res = await call('post', 'api/v1/upload', null, formData, "image");
                // if (res.data.success === true) {
                //   console.log(res)
                // }

                if (res.data.success === true) {
                    let myImage = res.data.urlsArray[0].split(".com/")[1]
                    const newImage = API_URL + myImage
                    setImageUrl(newImage)
                }

            } catch (err) {
                console.log(err);
                setErrorMessage(err.response.data.message);
            }
        }
    }

    const getRoles = () => {
        (async () => {
            try {
                const res = await call(
                    'get',
                    'api/v1/admin/roles',
                    { limit: 1, skip: 0, sort: "asc", pagination: 0 },
                    null
                );
                setRolesData(res.data.data ? res.data.data : []);
            } catch (err) {
                console.log(err);
                setRolesData([]);
            }
        })();
    };

    const checkEditValidation = () => {
        let emailValid =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        let stringName = /^[a-zA-Z\s._]+$/;
        let stringNameLast = /^[a-zA-Z\s._]+$/;
        let passwordValid =
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!""#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

        let formIsValid = true;

        if (!email) {
            setErrorEmail('Please enter your email.');
            formIsValid = false;
        } else if (!emailValid.test(email)) {
            setErrorEmail('Please enter your email in a valid format.');
            formIsValid = false;
        } else {
            setErrorEmail('');
        }

        if (!firstName) {
            setErrorFirstName('Enter your first name.');
            formIsValid = false;
        } else if (!stringName.test(firstName)) {
            setErrorFirstName('First name is invalid.');
            formIsValid = false;
        } else if (firstName.length > 50) {
            setErrorFirstName('Max 50 characters allowed.');
            formIsValid = false;
        } else {
            setErrorFirstName('');
        }

        if (!lastName) {
            setErrorLastName('Enter your last name.');
            formIsValid = false;
        } else if (!stringNameLast.test(lastName)) {
            setErrorLastName('Last name is invalid.');
            formIsValid = false;
        } else if (lastName.length > 50) {
            setErrorLastName('Max 50 characters allowed.');
            formIsValid = false;
        } else {
            setErrorLastName('');
        }

        if (password !== "" && !passwordValid.test(password)) {
            setErrorPassword(
                'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character. The special characters that can be used are [!""#$%&\'()*+,-./:;<=>?@[]^_`{|}~]'
            );
            formIsValid = false;
        } else {
            setErrorPassword('');
        }

        if (phoneNumber === 0) {
            setErrorPhoneNumber('Please enter the phone number.');
            formIsValid = false;
        } else if (phoneNumber.length < 10) {
            setErrorPhoneNumber('Please enter the valid number.');
            formIsValid = false;
        } else if (!phoneCountValidation()) {
            setErrorPhoneNumber('Please enter the valid number.');
            formIsValid = false;
        }
        else {
            setErrorPhoneNumber('');
        }
        return formIsValid;
    };

    const handleEditSubmit = async () => {
        if (checkEditValidation()) {
            let bodyData = {
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phoneNumber,
                registration_type: 'email',
                role_id: selectedRole,
                userId: null
            };

            if (password !== "") {
                bodyData["password"] = password
            }

            if (imageUrl && imageUrl !== "") {
                bodyData["profile_img"] = imageUrl
            }


            try {
                const res = await call('patch', 'api/v1/admin/edit-admin', null, bodyData);
                // setLoading(false);
                toast.success('User updated Successfully!', {
                    position: toast.POSITION.BOTTOM_LEFT
                });
                getUserData()
            } catch (err) {
                console.log(err);
                setErrorMessage(err.response.data.message);
            }
        }
    }

    useEffect(() => {
        getRoles()
        getUserData()
    }, [])


    return (
        <form>
            <div style={{ padding: "20px 200px 100px 200px" }}>
                <Grid containe spacing={3} xs={12} sx={{ borderRadius: "8px", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)", padding: "50px 50px 50px 50px", marginTop: "50px", backgroundColor: "#f0f0f0" }}>
                    <H2>My Account</H2>
                    <Grid container spacing={3} sx={{ flexDirection: { xs: "column-reverse", sm: "column-reverse", md: "column-reverse", lg: "row" } }}>
                        <Grid item lg={9}>
                            <Grid container spacing={3}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="text"
                                        name="First Name"
                                        label="First Name"
                                        variant="outlined"
                                        // onBlur={handleBlur}
                                        value={firstName}
                                        onClick={() => {
                                            setErrorFirstName('');
                                            setErrorMessage('');
                                        }}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        helperText={errorFirstName}
                                        error={Boolean(errorFirstName)}
                                        sx={{ mb: 3, mt: 2 }}
                                    />
                                </Grid>

                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="email"
                                        name="text"
                                        label="Last Name"
                                        variant="outlined"
                                        // onBlur={handleBlur}
                                        value={lastName}
                                        onClick={() => {
                                            setErrorLastName('');
                                            setErrorMessage('');
                                        }}
                                        onChange={(e) => setLastName(e.target.value)}
                                        helperText={errorLastName}
                                        error={Boolean(errorLastName)}
                                        sx={{ mb: 3, mt: 2 }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container direction={"column"} xs={12}>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        type="email"
                                        name="email"
                                        label="Email"
                                        variant="outlined"
                                        // onBlur={handleBlur}
                                        value={email}
                                        onClick={() => {
                                            setErrorEmail('');
                                            setErrorMessage('');
                                        }}
                                        onChange={(e) => setEmail(e.target.value)}
                                        helperText={errorEmail}
                                        error={Boolean(errorEmail)}
                                        sx={{ mb: 3 }}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextField
                                        fullWidth
                                        size="small"
                                        name="password"
                                        type={showPassword ? 'text' : 'password'}
                                        label="Password"
                                        variant="outlined"
                                        // onBlur={handleBlur}
                                        value={password}
                                        onClick={() => {
                                            setErrorPassword('');
                                            setErrorMessage('');
                                        }}
                                        onChange={(e) => setPassword(e.target.value)}
                                        helperText={errorPassword}
                                        error={Boolean(errorPassword)}
                                        sx={{ mb: 3 }}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        size="small"
                                                    >
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                </Grid>
                            </Grid>

                        </Grid>
                        <Grid item lg={3}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", paddingTop: "25px" }}>
                                <ImageVerify imageURL={imageUrl && imageUrl !== "" ? imageUrl : null} width={"100px"} height={"100px"}></ImageVerify>
                                <Button component='label'>Upload <input type='file' hidden accept="image/png, image/jpeg" onChange={(e) => handleImageChange(e)}></input></Button>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <PhoneInput
                                country={'ae'}
                                placeholder="Phone"
                                inputStyle={{
                                    width: '100%',
                                    backgroundColor: "#f0f0f0"
                                }}
                                value={phoneNumber}
                                size="medium"
                                countryCodeEditable={false}
                                specialLabel={''}
                                onChange={(value, country, e, formattedValue) => {
                                    setPhoneNumber(`+${country.dialCode}-${value.slice(country.dialCode.length)}`);
                                    setPhoneCount(country.countryCode);
                                }}
                            />
                            {errorPhoneNumber && (
                                <Typography style={{ marginTop: '1rem' }} sx={{ color: textError }}>
                                    {errorPhoneNumber}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl
                                variant="outlined"
                                margin={"1"}
                                style={{ width: "100%" }}
                            >
                                <InputLabel id="test-select-label">Role</InputLabel>
                                <Select
                                    style={{}}
                                    variant="outlined"
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                    labelId="test-select-label"
                                    label={"Role"}
                                >
                                    {rolesData && rolesData.length > 0 ?
                                        rolesData.map((ele) => {
                                            return (
                                                <MenuItem value={ele.id}>
                                                    {ele.name}
                                                </MenuItem>
                                            )
                                        }) : <></>}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{ mt: 2, backgroundColor: '#0432A3' }}
                                onClick={() => handleEditSubmit()}
                            >
                                Update User
                            </Button>
                            {errorMessage && (
                                <Typography style={{ marginTop: '1rem' }} sx={{ color: textError }}>
                                    {errorMessage}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {/* <div style={{ alignItems: "center", justifyContent: "center", display: "flex", flexDirection: "column", borderRadius: "8px", boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)", padding: "50px 100px 100px 100px", marginTop: "50px", backgroundColor: "#f0f0f0" }}>
                    
                </div> */}

            </div>
        </form>
    );
}
