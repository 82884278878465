import * as React from 'react';
import axios from 'axios';
import { API_URL } from 'app/constants';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTheme, TextField, Grid, InputLabel, InputAdornment } from '@mui/material';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import { useState } from 'react';
import { call } from '../../utils/apiCall';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import ImageVerify from 'app/utils/imageVerify';

const H2 = styled('h2')(({ theme }) => ({
    fontSize: '24px',
    fontWeight: '600',
    color: '01052D',
    marginTop: '0px',
    marginBottom: '30px'
}));


export default function EditMembership(props) {
    const numberRegex = /^(\d{1,4}(\.\d{1,2})?|10000)$/;;
    var regex = /^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/;
    let textRegEx = /^(?!\s)(.{0,250})$/;
    const { toggle } = props;
    const { palette } = useTheme();
    const textError = palette.error.main;
    const params = useParams()
    const [name, setName] = useState("")
    const [nameError, setNameError] = useState("")
    const [description, setDescription] = useState("")
    const [descriptionError, setDescriptionError] = useState("")
    const [pricingList, setPricingList] = useState("")
    const [discount, setDiscount] = useState("")
    const [discountError, setDiscountError] = useState("")
    const [emptyValueId, setEmptyValueId] = useState([])
    const [invalidValueId, setInvalidValueId] = useState([])
    const [emptyValueError, setEmptyValueError] = useState("Price is required.")
    const [invalidValueError, setInvalidValueError] = useState("Please enter valid price ranging from 0 to 9999 and upto 2 decimal places.")
    const [imageUrl, setImageUrl] = useState("")
    const navigate = useNavigate()


    const handleImageChange = async (e) => {
        if (e.target.files[0].type == "image/jpeg" || e.target.files[0].type == "image/png") {
            try {
                let formData = new FormData()
                formData.append("image", e.target.files[0])
                const res = await axios.post(`${API_URL}api/v1/upload`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'Accept-Language': 'en'
                    }
                })
                // const res = await call('post', 'api/v1/upload', null, formData, "image");
                // if (res.data.success === true) {
                //   console.log(res)
                // }

                if (res.data.success === true) {
                    let myImage = res.data.urlsArray[0].split(".com/")[1]
                    const newImage = API_URL + myImage
                    setImageUrl(newImage)
                }

            } catch (err) {
                console.log(err);
            }
        }
    }

    const handlePriceChange = (index, value) => {
        const updatedItems = [...pricingList]
        updatedItems[index].value = value
        setPricingList(updatedItems)
    }

    const handleSubmit = async () => {
        let formValid = true

        if (name == "") {
            formValid = false
            setNameError("Name is required.")
        } else if (!textRegEx.test(name)) {
            formValid = false
            setNameError("Please enter valid name upto 250 characters.")
        }

        if (description == "") {
            formValid = false
            setDescriptionError("Description is required.")
        } else if (!textRegEx.test(description)) {
            formValid = false
            setDescriptionError("Please enter valid description upto 250 characters.")
        }

        if (discount == "") {
            formValid = false
            setDiscountError("Discount is required.")
        } else if (!regex.test(discount)) {
            formValid = false
            setDiscountError("Please enter valid discount ranging from 0 to 100 and upto 2 decimal places.")
        }

        if (params.id !== 1) {
            for (let i = 0; i < pricingList.length; i++) {
                if (pricingList[i].value == "") {
                    formValid = false
                    setEmptyValueId(prevData => {
                        let updateData = [...prevData]
                        updateData.push(i)
                        return updateData
                    })
                } else if (!numberRegex.test(pricingList[i].value)) {
                    formValid = false
                    setInvalidValueId(prevData => {
                        let updateData = [...prevData]
                        updateData.push(i)
                        return updateData
                    })
                }
            }
        }

        if (formValid) {
            const bodyData = {
                membershipName: name,
                membershipDescription: description,
                membershipDiscount: params.id == 1 ? 0 : parseFloat(discount),
                membershipBackground: params.id == 1 ? null : imageUrl,
                membershipId: parseInt(params.id)
            }

            let pricingData = []
            for (let i = 0; i < pricingList.length; i++) {
                let newObj = pricingList[i]
                delete newObj.membershipId
                delete newObj.name
                delete newObj.description
                delete newObj.createdAt
                delete newObj.discount
                delete newObj.background

                pricingData.push(newObj)
            }

            if (params.id != "1") {
                bodyData["pricingData"] = pricingData
            } else {
                bodyData["pricingData"] = []
            }

            try {
                const res = await call(
                    'patch',
                    `api/v1/admin/update-membership`,
                    null,
                    bodyData
                );
                if (res.data.success) {
                    navigate('/manage-subscription')
                    toast.success("Membership updated successfully.", { position: toast.POSITION.BOTTOM_LEFT })
                }
            } catch (error) {
                navigate('/manage-subscription')
                toast.error("Something went wrong.", { position: toast.POSITION.BOTTOM_LEFT })
            }
        }



    }

    const getPageData = () => {
        (async () => {
            try {
                const res = await call(
                    'get',
                    `api/v1/admin/view-membership/${params.id}`,
                    null,
                    null
                );
                if (res.data.success) {
                    setName(res.data.data[0].name)
                    setDescription(res.data.data[0].description)
                    setPricingList(res.data.data)
                    setDiscount(res.data.data[0].discount)
                    setImageUrl(res.data.data[0].background)
                }

            } catch (err) {
                console.log(err);
            }
        })();
    }

    useEffect(() => {
        getPageData()
    }, [])


    return (
        <form style={{ padding: "30px" }}>
            <h3 className="breadcumbTitle" style={{}}>
                <span className='bread-link' onClick={() => navigate("/manage-subscription")} onMouseEnter={(e) => e.target.style.color = '#2563a4'} onMouseLeave={(e) => e.target.style.color = 'black'}> Subscription Management {'>'} </span>
                <span> Edit Subscription </span>
            </h3>
            <H2>Edit Subscription</H2>
            <h2>Subscription</h2>
            <Grid container spacing={3} width={600}>

                <Grid item >
                    <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="Name"
                        label="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onClick={() => setNameError("")}
                        helperText={nameError}
                        error={Boolean(nameError)}
                        variant="outlined"
                        sx={{ mb: 3 }}
                    />

                    <TextField
                        fullWidth
                        size="small"
                        type="text"
                        name="Description"
                        label="Description"
                        variant="outlined"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        minRows={5}
                        onClick={() => setDescriptionError("")}
                        helperText={descriptionError}
                        error={Boolean(descriptionError)}
                        sx={{ mb: 3 }}
                    />

                    {
                        params.id == 1 ?
                            <></> :
                            <>
                                <>
                                    <InputLabel>Background Image</InputLabel>
                                    <div style={{ display: "flex", flexDirection: "column", width: "200px", alignItems: "center", justifyContent: "center" }}>
                                        <ImageVerify imageURL={imageUrl && imageUrl !== "" ? imageUrl : null} width={"100%"} height={"150px"}></ImageVerify>
                                        <Button component='label'>Upload <input type='file' hidden accept="image/png, image/jpeg" onChange={(e) => handleImageChange(e)}></input></Button>
                                    </div>
                                </>

                                <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="Discount"
                                    label="Discount"
                                    variant="outlined"
                                    value={discount}
                                    onChange={(e) => setDiscount(e.target.value)}
                                    minRows={5}
                                    onClick={() => setDiscountError("")}
                                    helperText={discountError}
                                    error={Boolean(discountError)}
                                    sx={{ mb: 3, mt: 3 }}
                                />

                            </>
                    }
                    <h2>Pricing</h2>
                    <Grid container spacing={1}>
                        {
                            pricingList && pricingList.length > 0 ?
                                pricingList.map((ele, index) => {
                                    return (
                                        <>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    name="Category"
                                                    label="Category"
                                                    variant="outlined"
                                                    value={ele.type == "cat1" ? "Category 1" : ele.type == "cat2" ? "Category 2" : "Category 3"}
                                                    disabled
                                                    sx={{ mb: 3, mt: 2 }}
                                                />
                                            </Grid>
                                            <Grid item lg={6} md={6} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    name="Price"
                                                    label="Price"
                                                    variant="outlined"
                                                    onChange={(e) => handlePriceChange(index, e.target.value)}
                                                    disabled={ele.membershipId == "1" ? true : false}
                                                    value={ele.value}
                                                    onClick={() => {
                                                        setEmptyValueId(prevData => {
                                                            const updatedData = prevData.filter(item => item !== index);
                                                            return updatedData;
                                                        })
                                                        setInvalidValueId(prevData => {
                                                            const updatedData = prevData.filter(item => item !== index);
                                                            return updatedData;
                                                        })
                                                    }}
                                                    helperText={emptyValueId.includes(index) ? emptyValueError : invalidValueId.includes(index) ? invalidValueError : ""}
                                                    error={Boolean(emptyValueId.includes(index) || invalidValueId.includes(index))}
                                                    // onBlur={handleBlur}
                                                    sx={{ mb: 3, mt: 2 }}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <AttachMoneyOutlinedIcon />
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            {/* <Grid item lg={1} md={6} sm={6} xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ mt: 2, backgroundColor: '#0432A3' }}
                                                    onClick={() => ele.id ? removeOldData(ele.id, index) : removeNewData(index)}
                                                >
                                                    -
                                                </Button>
                                            </Grid> */}
                                        </>
                                    )
                                })
                                :
                                <></>
                        }
                    </Grid>
                </Grid>

            </Grid>


            <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                sx={{ mt: 4, backgroundColor: '#0432A3', width: "160px" }}
            >
                Update Plan
            </Button>
        </form>
    );
}
