import {
  Card,
  Grid,
  styled,
  useTheme,
  MenuItem,
  Select,
  Box,
  Button,
  InputAdornment,
  TextField
} from '@mui/material';
import { Fragment } from 'react';
import PaginationTable from './components/userTable';
import { useEffect, useState } from 'react';
import { call } from '../../utils/apiCall';
import SearchIcon from '@mui/icons-material/Search';
import AddUserModal from './components/AddUserModal';
import AlertDialog from './components/Alert';
import PasswordResetModal from './components/ResetPassword';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize'
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary
}));

const H2 = styled('h2')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  color: '01052D',
  marginTop: '0px',
  marginBottom: '30px'
}));

const ManageTalentpool = () => {
  const { palette } = useTheme();
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addUserModal, setAddUserModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [alertDeleteModal, setAlertDeleteModal] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [skipData, setSkipData] = useState(0);
  const [searchKey, setSearchKey] = useState();
  const [userId, setUserId] = useState();
  const [userStatus, setUserStatus] = useState();
  const [sortData, setSortData] = useState('asc');

  useEffect(() => {
    getUserData();
  }, [searchKey, skipData, rowsPerPage, sortData]);

  const getUserData = () => {
    (async () => {
      try {
        let query = { limit: rowsPerPage, skip: skipData }
        if (searchKey) {
          query["search"] = searchKey
        }
        const res = await call(
          'get',
          'api/v1/admin/talent-pool',
          query,
          null
        );
        setUsersData(res.data.data ? res.data.data : []);
        setTotalItems(res.data.totalItems ? res.data.totalItems : 0);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setUsersData([]);
      }
    })();
  };

  const deleteUser = async (id) => {
    try {
      const res = await call('delete', `api/v1/admin/user/${id}`, null, null);
      setLoading(false);
      getUserData();
      // let letnewData = usersData.filter((item) => item.id !== id);
      // setUsersData(letnewData);
      setAlertDeleteModal(!alertDeleteModal);
      toast.success('User Deleted Successfully!', {
        position: toast.POSITION.BOTTOM_LEFT
      });
    } catch (err) {
      console.log(err);
    }
  };

  const blockUser = async (id) => {
    let data = {
      status: userStatus === 'block' ? false : true
    };
    try {
      const res = await call('patch', `api/v1/admin/user/${id}`, null, data);
      setLoading(false);
      getUserData();
      setAlertModal(!alertModal);
      toast.success(
        userStatus === 'block' ? 'User Block Successfully!' : 'User Active Successfully!',
        {
          position: toast.POSITION.BOTTOM_LEFT
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  console.log(searchKey);

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <H2>Talentpool Management</H2>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>

              </div>
              <div style={{ marginRight: '20px' }}>
                <TextField
                  id="search"
                  type="search"
                  // label="Search"
                  placeholder="Search..."
                  size="small"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  // sx={{ width: '50%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <PaginationTable
                data={usersData}
                alertModal={alertModal}
                setAlertModal={setAlertModal}
                alertDeleteModal={alertDeleteModal}
                setAlertDeleteModal={setAlertDeleteModal}
                addUserModal={addUserModal}
                setAddUserModal={setAddUserModal}
                resetPassModal={resetPassModal}
                setResetPassModal={setResetPassModal}
                totalItems={totalItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSkipData={setSkipData}
                setUserId={setUserId}
                userStatus={userStatus}
                setUserStatus={setUserStatus}
                sortData={sortData}
                setSortData={setSortData}
              />
            </div>
          </Grid>
        </Grid>
      </ContentBox>
      {addUserModal && (
        <AddUserModal modal={addUserModal} toggle={() => setAddUserModal(!addUserModal)} />
      )}

      {alertModal && (
        <AlertDialog
          modal={alertModal}
          toggle={() => setAlertModal(!alertModal)}
          confirmFunc={() => blockUser(userId)}
          title={userStatus === 'block' ? 'Block User Confirmation' : 'Activate User Confirmation'}
          description={
            userStatus === 'block'
              ? 'Are you sure to block the user and its permissions?'
              : 'Are you sure to activate user and its permissions?'
          }
        />
      )}

      {alertDeleteModal && (
        <AlertDialog
          modal={alertDeleteModal}
          toggle={() => setAlertDeleteModal(!alertDeleteModal)}
          confirmFunc={() => deleteUser(userId)}
          title="Delete User Confirmation"
          description="Are you want to sure delete the user?"
        />
      )}

      {resetPassModal && (
        <PasswordResetModal
          modal={resetPassModal}
          toggle={() => setResetPassModal(!resetPassModal)}
          userId={userId}
        />
      )}
      <ToastContainer />
    </Fragment>
  );
};

export default ManageTalentpool;
