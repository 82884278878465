import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip
} from '@mui/material';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import BlockIcon from '@mui/icons-material/Block';
import moment from 'moment';

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0 } }
  },
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #D1DFFF'
  },
  '& .MuiTableRow-root:nth-child(even)': {
    backgroundColor: '#f2f2f2', // Alternate row color
  },
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: '15px',
  fontWeight: 600,
  color: 'white',
  backgroundColor: "rgba(39, 75, 175, 0.8)",
  '&:first-of-type': {
    borderTopLeftRadius: '15px', // Curved edge on the top-left corner
  },
  '&:last-of-type': {
    borderTopRightRadius: '15px', // Curved edge on the top-right corner
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`
}));

const PaginationTable = ({
  data,
  alertModal,
  setAlertModal,
  alertDeleteModal,
  setAlertDeleteModal,
  addUserModal,
  setAddUserModal,
  resetPassModal,
  setResetPassModal,
  totalItems,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  setSkipData,
  setUserId,
  userStatus,
  setUserStatus,
  setSortData,
  sortData
}) => {
  const handleChangePage = (_, newPage) => {
    setSkipData(newPage * rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setSkipData(0);
  };

  console.log(data);

  return (
    <Box width="100%" overflow="auto">
      <StyledTable style={{ width: "1500px", overflowX: "auto", wordBreak: "break-word" }}>
        <TableHead>
          <TableRow>
            <StyledTableHead align="left" style={{ paddingLeft: "20px" }}>Name</StyledTableHead>
            <StyledTableHead align="center">Email</StyledTableHead>
            <StyledTableHead align="center">
              Joined Date
              {sortData === 'desc' ? (
                <IconButton onClick={() => setSortData('asc')}>
                  <Icon sx={{ color: 'white' }}>
                    <Tooltip title="Sort Date">
                      <ArrowDownwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              ) : (
                <IconButton onClick={() => setSortData('desc')}>
                  <Icon sx={{ color: 'white' }}>
                    <Tooltip title="Sort Date">
                      <ArrowUpwardIcon />
                    </Tooltip>
                  </Icon>
                </IconButton>
              )}
            </StyledTableHead>
            <StyledTableHead align="center">Profession</StyledTableHead>
            <StyledTableHead align="center">Experience (In Yrs.)</StyledTableHead>
            <StyledTableHead align="center">Desire Country</StyledTableHead>
            <StyledTableHead align="center">Role</StyledTableHead>
            <StyledTableHead align="center">Work Type</StyledTableHead>
            <StyledTableHead align="center">Action</StyledTableHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((user, index) => (
            <TableRow key={index}>
              <StyledTableCell align="left" style={{ paddingLeft: "20px" }}>
                {user?.user?.full_name}
              </StyledTableCell>
              <StyledTableCell align="center">{user.email}</StyledTableCell>
              <StyledTableCell align="center">
                {moment(user.createdAt).format('ll')}
              </StyledTableCell>
              <StyledTableCell align="center">
                {user?.profession.length ? user?.profession.join(", ") : "N/A"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {user?.experience ? user?.experience : "N/A"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {user?.desire_country ? user?.desire_country : "N/A"}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ textTransform: "capitalize" }}>
                {user?.type}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ textTransform: "capitalize" }}>
                {user?.work_type ? user?.work_type : "N/A"}
              </StyledTableCell>
              <StyledTableCell align="center">
                {/* <IconButton
                  color="#676A79"
                  onClick={() => {
                    setAlertModal(!alertModal);
                    setUserId(user.id);
                  }}
                >
                  <BlockIcon />
                </IconButton> */}
                {/* <IconButton onClick={() => setAddUserModal(!addUserModal)}>
                  <EditIcon />
                </IconButton> */}
                {user.status === false ? (
                  <IconButton
                    onClick={() => {
                      setAlertModal(!alertModal);
                      setUserId(user.id);
                      setUserStatus('unblock');
                    }}
                  >
                    <Icon color="error">
                      <Tooltip title="Unblock User">
                        <BlockIcon />
                      </Tooltip>
                    </Icon>
                  </IconButton>
                ) : (
                  <IconButton
                    onClick={() => {
                      setAlertModal(!alertModal);
                      setUserId(user.id);
                      setUserStatus('block');
                    }}
                  >
                    {/* <Icon color="error">close</Icon> */}
                    <Tooltip title="Block User">
                      <BlockIcon />
                    </Tooltip>
                  </IconButton>
                )}
                <IconButton
                  onClick={() => {
                    setResetPassModal(!resetPassModal);
                    setUserId(user.id);
                  }}
                >
                  <Tooltip title="Reset Password">
                    <LockPersonIcon />
                  </Tooltip>
                </IconButton>

                <IconButton
                  onClick={() => {
                    setAlertDeleteModal(!alertDeleteModal);
                    setUserId(user.id);
                  }}
                >
                  <Tooltip title="Delete">
                    <DeleteIcon />
                  </Tooltip>
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>

      <TablePagination
        sx={{ px: 2 }}
        page={page}
        component="div"
        rowsPerPage={rowsPerPage}
        count={totalItems}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[5, 10, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}
        nextIconButtonProps={{ 'aria-label': 'Next Page' }}
        backIconButtonProps={{ 'aria-label': 'Previous Page' }}
      />
    </Box>
  );
};

export default PaginationTable;
