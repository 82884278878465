import {
  Box,
  Icon,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Checkbox
} from '@mui/material';
import { useEffect } from 'react';

const StyledTable = styled(Table)(() => ({
  whiteSpace: 'pre',
  '& thead': {
    '& tr': { '& th': { paddingLeft: 0, paddingRight: 0 } }
  },
  '& tbody': {
    '& tr': { '& td': { paddingLeft: 0, textTransform: 'capitalize' } }
  },
  '& .MuiTableCell-root': {
    borderBottom: '1px solid #D1DFFF'
  }
}));

const StyledTableHead = styled(TableCell)(() => ({
  fontSize: '15px',
  fontWeight: 600,
  color: '#01052D'
}));

const StyledTableCell = styled(TableCell)(() => ({
  color: `#676A79`
}));

const PermissionTable = ({
  data,
  moduleData,
  setModuleData,
  targetPath,
  setName
}) => {

  const updateData = (index) => (e) => {
    const newArray = moduleData.map((item, i) => {
      let newTarget
      if (e.target.value == 1) {
        newTarget = 0
      } else {
        newTarget = 1
      }
      if (index === i) {
        return { ...item, [e.target.name]: newTarget };
      } else {
        return item;
      }
    });
    setModuleData(newArray);
  }

  useEffect(() => {
    let newData = []
    if (targetPath === "add-role") {
      for (let i = 0; i < data.length; i++) {
        let myObj = data[i]
        myObj["view"] = 0
        myObj["add"] = 0
        myObj["edit"] = 0
        myObj["delete"] = 0
        myObj["export"] = 0
        newData.push(myObj)
      }
    } else {
      for (let i = 0; i < data.length; i++) {
        let myObj = data[i]
        myObj["role_name"] = myObj.role.name
        delete myObj.role
        myObj["name"] = myObj.module.name
        delete myObj.module
        myObj["view"] = myObj.view ? 1 : 0
        myObj["add"] = myObj.add ? 1 : 0
        myObj["edit"] = myObj.edit ? 1 : 0
        myObj["delete"] = myObj.delete ? 1 : 0
        myObj["export"] = myObj.export ? 1 : 0
        newData.push(myObj)
      }
    }
    console.log(newData)
    setModuleData(newData)
    if (newData.length) {
      if (newData[0].role_name) {
        setName(newData[0].role_name)
      }
    }
  }, [data])


  return (
    <Box width="100%" overflow="auto">
      <StyledTable>
        <TableHead>
          <TableRow>
            <StyledTableHead align="center">Module Name</StyledTableHead>
            <StyledTableHead align="center">View</StyledTableHead>
            <StyledTableHead align="center">Add</StyledTableHead>
            <StyledTableHead align="center">Edit</StyledTableHead>
            <StyledTableHead align="center">Delete</StyledTableHead>
            <StyledTableHead align="center">Export</StyledTableHead>
          </TableRow>
        </TableHead>
        <TableBody>
          {moduleData && moduleData.map((module, index) => (
            <TableRow key={index}>
              <StyledTableCell align="center">
                {module.name}
              </StyledTableCell>
              <StyledTableCell align="center">
                <Checkbox value={module.view} name='view' onChange={updateData(index)} checked={module.view == 0 ? false : true} disabled={targetPath === "view-role" ? true : false}></Checkbox>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Checkbox value={module.add} name='add' onChange={updateData(index)} checked={module.add == 0 ? false : true} disabled={targetPath === "view-role" ? true : false}></Checkbox>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Checkbox value={module.edit} name='edit' onChange={updateData(index)} checked={module.edit == 0 ? false : true} disabled={targetPath === "view-role" ? true : false}></Checkbox>
              </StyledTableCell>
              <StyledTableCell align='center'>
                <Checkbox value={module.delete} name='delete' onChange={updateData(index)} checked={module.delete == 0 ? false : true} disabled={targetPath === "view-role" ? true : false}></Checkbox>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Checkbox value={module.export} name='export' onChange={updateData(index)} checked={module.export == 0 ? false : true} disabled={targetPath === "view-role" ? true : false}></Checkbox>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </StyledTable>
    </Box>
  );
};

export default PermissionTable;
