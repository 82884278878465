import { createContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { MatxLoading } from 'app/components';
import { authApi } from 'app/api/authAPI.js';
import jwtDecode from 'jwt-decode';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;

  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, isAuthenticated, isInitialised: true, user };
    }

    case 'LOGIN': {
      localStorage.setItem('user', JSON.stringify(action.payload));
      return { ...state, isAuthenticated: true, user: action.payload };
    }

    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }

    // case 'REGISTER': {
    //   const { user } = action.payload;

    //   return { ...state, isAuthenticated: true, user };
    // }

    default:
      return state;
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  login: () => {},
  logout: () => {},
  forgot_password: () => {},
  reset_password: () => {}
});

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (email, password) => {
    const response = await authApi.login(email, password);
    dispatch({ type: 'LOGIN', payload: response.data });
  };

  // const register = async (email, username, password) => {
  //   const response = await axios.post('/api/auth/register', { email, username, password });
  //   const { user } = response.data;

  //   dispatch({ type: 'REGISTER', payload: { user } });
  // };

  const forgot_password = async (email) => {
    await authApi.forgotPassword(email).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  const otp_password = async (data) => {
    return await authApi.otpPassword(data).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  const reset_password = async (data, token) => {
    await authApi.resetPassword(data, token).then(
      (data) => {
        return Promise.resolve(data);
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  const logout = () => {
    localStorage.clear();
    dispatch({ type: 'LOGOUT' });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        let user = window.localStorage.getItem('user');
        // console.log(user);
        if (accessToken) {
          setSession(accessToken, user);

          //TODO: Add api to set user in context
          user = JSON.parse(user);
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          console.log('false');
          dispatch({
            type: 'INIT',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err, 'error');
        dispatch({
          type: 'INIT',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    })();
  }, []);

  // SHOW LOADER
  if (!state.isInitialised) return <MatxLoading />;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        forgot_password,
        otp_password,
        reset_password
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
