import {
  Card,
  Grid,
  styled,
  useTheme,
  MenuItem,
  Select,
  Box,
  Button,
  InputAdornment,
  TextField
} from '@mui/material';
import { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { call } from '../../utils/apiCall';
import SearchIcon from '@mui/icons-material/Search';
import PaginationTable from './components/paymentTable';
// import AddCompanyModal from './components/AddCompany';
// import AlertDialog from './components/Alert';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const CardHeader = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginRight: '.5rem',
  textTransform: 'capitalize'
}));

const SubTitle = styled('span')(({ theme }) => ({
  fontSize: '1rem',
  color: theme.palette.text.secondary
}));

const H4 = styled('h4')(({ theme }) => ({
  fontSize: '1rem',
  fontWeight: '500',
  marginBottom: '16px',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary
}));

const WalletManagement = () => {
  const { palette } = useTheme();
  const [companyData, setCompanyData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const [editCompanyModal, setEditCompanyModal] = useState(false);
  const [editCompanyData, setEditCompanyData] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [companyId, setCompanyId] = useState('');
  const [alertDeleteModal, setAlertDeleteModal] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [skipData, setSkipData] = useState(0);
  const [searchKey, setSearchKey] = useState();

  useEffect(() => {
    getCompanyData();
  }, [searchKey, skipData, rowsPerPage]);

  const getCompanyData = () => {
    (async () => {
      try {
        const res = await call(
          'get',
          'api/v1/admin/company',
          searchKey
            ? { search: searchKey, limit: rowsPerPage, skip: skipData }
            : { limit: rowsPerPage, skip: skipData },
          null
        );
        setCompanyData(res.data.data ? res.data.data : []);
        setTotalItems(res.data.totalItems ? res.data.totalItems : 0);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setCompanyData([]);
      }
    })();
  };

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <h2>View and Manage Wallet</h2>

            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                {/* <Button
                    variant="contained"
                    size="small"
                    onClick={() => setAddCompanyModal(!addCompanyModal)}
                  >
                    + Add Company
                  </Button> */}
              </div>
              <div>
                <TextField
                  id="search"
                  type="search"
                  // label="Search"
                  placeholder="Search..."
                  size="small"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  // sx={{ width: '50%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: "20px" }}>
              <PaginationTable
                data={companyData}
                // alertModal={alertModal}
                // setAlertModal={setAlertModal}
                // setCompanyId={setCompanyId}
                // alertDeleteModal={alertDeleteModal}
                // setAlertDeleteModal={setAlertDeleteModal}
                // editCompanyModal={editCompanyModal}
                // setEditCompanyModal={setEditCompanyModal}
                // setEditCompanyData={setEditCompanyData}
                totalItems={totalItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSkipData={setSkipData}
              />
            </div>
          </Grid>
        </Grid>
      </ContentBox>
      {/* {addCompanyModal && (
          <AddCompanyModal
            modal={addCompanyModal}
            toggle={() => setAddCompanyModal(!addCompanyModal)}
          />
        )} */}

      {/* {editCompanyModal && (
          <AddCompanyModal
            modal={editCompanyModal}
            toggle={() => setEditCompanyModal(!editCompanyModal)}
            editCompanyData={editCompanyData}
            getCompanyData={() => getCompanyData()}
          />
        )} */}

      {/* {alertModal && (
          <AlertDialog
            modal={alertModal}
            toggle={() => setAlertModal(!alertModal)}
            title="Block Company"
            description="Are you sure to block the company?"
            confirmFunc={() => blockCompany(companyId)}
          />
        )} */}
      {/*   
        {alertDeleteModal && (
          <AlertDialog
            modal={alertDeleteModal}
            toggle={() => setAlertDeleteModal(!alertDeleteModal)}
            title="Delete Company"
            description="Are you want to sure delete the company?"
            confirmFunc={() => deleteCompany(companyId)}
          />
        )} */}
      <ToastContainer />
    </Fragment>
  );
};

export default WalletManagement;
