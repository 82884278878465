import {
  Grid,
  styled,
  TextField,
  Button
} from '@mui/material';
import { Fragment } from 'react';
import { useEffect, useState } from 'react';
import { call } from '../../utils/apiCall';
import { ToastContainer, toast } from 'react-toastify';
import PermissionTable from './components/permissionTable';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const H2 = styled('h2')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  color: '01052D',
  marginTop: '0px',
  marginBottom: '30px'
}));

const AddRole = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const params = useParams()
  let nameRegEx = /^(?=.{3,15}$)[a-z]+(?:['_.\s][a-z]+)*$/
  const targetPath = location.pathname.split("/")[2]
  const [rolesData, setRolesData] = useState([]);
  const [moduleData, setModuleData] = useState([])
  const [name, setName] = useState("")
  const [nameError, setNameError] = useState("")


  useEffect(() => {
    getRoleData();
  }, []);

  const getRoleData = () => {
    (async () => {
      let targetService
      if (targetPath == "add-role") {
        targetService = 'api/v1/admin/modules'
      } else {
        targetService = `api/v1/admin/view-role/${params.id}`
      }
      try {
        const res = await call(
          'get',
          targetService,
          {},
          null
        );
        setRolesData(res.data.data ? res.data.data : []);
      } catch (err) {
        console.log(err);
        setRolesData([]);
      }
    })();
  };

  const addRole = async () => {
    let nameValid = true
    if (name === "") {
      setNameError("Role name is required.")
      nameValid = false
    } else if (!nameRegEx.test(name)) {
      setNameError("Please enter valid role name. Min. Characters: 3, Max. Characters: 15")
      nameValid = false
    }

    if (!nameValid) {
      console.log("Validation Error.")
    } else {
      let permissionData = []
      for (let i = 0; i < moduleData.length; i++) {
        let myObj = moduleData[i]
        myObj["module_id"] = myObj.id
        delete myObj.id
        delete myObj.createdAt
        delete myObj.updatedAt
        delete myObj.name
        permissionData.push(myObj)
      }

      const bodyData = {
        roleName: name,
        permissions: permissionData
      }

      try {
        const res = await call(
          'post',
          'api/v1/admin/add-role',
          {},
          bodyData
        )

        if (res.data.success === true) {
          navigate("/roles-permission")
          toast.success("Role created successfully.", { position: toast.POSITION.BOTTOM_LEFT })
        }
      } catch (error) {
        console.log(error);
      }


    }
  }

  const updateRole = async () => {
    let nameValid = true
    if (name === "") {
      setNameError("Role name is required.")
      nameValid = false
    } else if (!nameRegEx.test(name)) {
      setNameError("Please enter valid role name. Min. Characters: 3, Max. Characters: 15")
      nameValid = false
    }

    if (!nameValid) {
      console.log("Validation Error.")
    } else {

      console.log(params.id)
      console.log(name)

      let finalData = []

      for (let i = 0; i < moduleData.length; i++) {
        let myObj = moduleData[i]
        delete myObj.name
        delete myObj.role_name
        finalData.push(myObj)
      }

      const bodyData = {
        roleName: name,
        roleId: params.id,
        permissions: finalData
      }

      try {
        const res = await call(
          'patch',
          'api/v1/admin/edit-role',
          {},
          bodyData
        )

        if (res.data.success === true) {
          navigate("/roles-permission")
          toast.success("Role updated successfully.", { position: toast.POSITION.BOTTOM_LEFT })
        }
      } catch (error) {
        console.log(error);
      }
    }
  }


  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <h3 className="breadcumbTitle" style={{}}>
              <span className='bread-link' onClick={() => navigate("/roles-permission")} onMouseEnter={(e) => e.target.style.color = '#2563a4'} onMouseLeave={(e) => e.target.style.color = 'black'}> Manage Roles {'>'} </span>
              {targetPath == "add-role" ? <span> Add Role </span> : targetPath == "view-role" ? <span> View Role </span> : <span> Edit Role </span>}
            </h3>
            {targetPath == "add-role" ? <H2>Add Role</H2> : targetPath == "view-role" ? <H2>View Role</H2> : <H2>Edit Role</H2>}
            <div style={{ display: 'flex', justifyContent: "left", columnGap: "15px", alignItems: 'center' }}>
              <TextField

                size="large"
                type="text"
                name="Role Name"
                label="Role Name"
                value={name}
                onClick={() => {
                  setNameError('');

                }}
                onChange={(e) => setName(e.target.value)}
                helperText={nameError}
                error={Boolean(nameError)}
                disabled={targetPath === "view-role" ? true : false}
                sx={{ width: "250px" }}
              />
              {
                targetPath === "add-role" ?
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => addRole()}
                    sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                  >
                    Add
                  </Button> :
                  targetPath === "view-role" ?
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => navigate("/roles-permission")}
                      sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                    >
                      Close
                    </Button> :
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => updateRole()}
                      sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                    >
                      Update
                    </Button>
              }

            </div>
            {/* <Grid item lg={6} md={6} sm={6} xs={12}>

              </Grid> */}
            {/* <TextField
                  size="large"
                  type="text"
                  name="Role Name"
                  label="Role Name"
                //   variant="outlined"
                //   value={firstName}
                //   onClick={() => {
                //     setErrorFirstName('');
                //     setErrorMessage('');
                //   }}
                //   onChange={(e) => setFirstName(e.target.value)}
                //   helperText={errorFirstName}
                //   error={Boolean(errorFirstName)}
                  sx={{ mb: 3, mt: 2 }}
              /> */}
            <div style={{ marginTop: '40px' }}>
              <PermissionTable
                data={rolesData}
                addRole={addRole}
                setModuleData={setModuleData}
                moduleData={moduleData}
                targetPath={targetPath}
                setName={setName}
              />
            </div>
          </Grid>
        </Grid>
      </ContentBox>
      <ToastContainer />
    </Fragment>
  );
};

export default AddRole;
