import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTheme, TextField, InputAdornment, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { call } from '../../../utils/apiCall';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const styles = styled(TextField)({
  '*.Mui-focused': {
    borderColor: 'transparent',
    outline: 'none'
  }
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function PasswordResetModal(props) {
  const { modal, toggle, userId } = props;
  const { palette } = useTheme();
  const textError = palette.error.main;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showComfirmPassword, setComfirmShowPassword] = useState(false);
  const [errorPassword, setErrorPassword] = useState('');
  const [errorConfirmPassword, setErrorConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const handleClickShowPassword2 = () => setComfirmShowPassword(!showComfirmPassword);
  const handleMouseDownPassword2 = () => setComfirmShowPassword(!showComfirmPassword);

  const validation = () => {
    let passwordValid = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!password) {
      setErrorPassword('Please Enter Your Password');
      return;
    }
    if (!passwordValid.test(password)) {
      setErrorPassword(
        'The password should be minimum 8 characters long and contain at least one : Upper and Lower Case Alphabet, Number, Special Character.'
      );
      return;
    }
    if (!confirmPassword) {
      setErrorConfirmPassword('Please Enter Confirm Password');
      return;
    }
    if (!(password === confirmPassword)) {
      setErrorMessage('Passwords do not match');
      return;
    }
    handleFormSubmit();
  };

  const handleFormSubmit = async (event) => {
    let resetPasswordData = {
      password: password
    };
    // setLoading(true);
    try {
      const res = await call('patch', `api/v1/admin/user/${userId}`, null, resetPasswordData);
      toast.success('Reset Password Successful!', {
        position: toast.POSITION.BOTTOM_LEFT
      });
      toggle();
    } catch (err) {
      console.log(err);
    }

    console.log(resetPasswordData);
  };

  return (
    <div>
      <BootstrapDialog maxWidth={'xs'} aria-labelledby="customized-dialog-title" open={modal}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          Reset Password
        </BootstrapDialogTitle>
        <DialogContent>
          <form>
            <TextField
              fullWidth
              size="small"
              name="password"
              type={showPassword ? 'text' : 'password'}
              label="New Password"
              variant="outlined"
              // onBlur={handleBlur}
              value={password}
              onClick={() => setErrorPassword('')}
              onChange={(e) => setPassword(e.target.value)}
              helperText={errorPassword}
              error={Boolean(errorPassword)}
              sx={{ mb: 1.5, mt: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      size="small"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <TextField
              fullWidth
              size="small"
              name="password"
              type={showComfirmPassword ? 'text' : 'password'}
              label="Confirm Password"
              variant="outlined"
              // onBlur={handleBlur}
              value={confirmPassword}
              onClick={() => setErrorConfirmPassword('')}
              onChange={(e) => setConfirmPassword(e.target.value)}
              helperText={errorConfirmPassword}
              error={Boolean(errorConfirmPassword)}
              sx={{ mb: 1.5, mt: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword2}
                      onMouseDown={handleClickShowPassword2}
                      size="small"
                    >
                      {showComfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={validation}
              sx={{ mt: 4, px: 10, backgroundColor: '#0432A3' }}
            >
              Reset Password
            </Button>
            {errorMessage && (
              <Typography style={{ marginTop: '1rem' }} sx={{ color: textError }}>
                {errorMessage}
              </Typography>
            )}
          </form>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={toggle}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
