import {
  Card,
  Grid,
  styled,
  useTheme,
  MenuItem,
  Select,
  Box,
  Button,
  InputAdornment,
  TextField
} from '@mui/material';
import { Fragment } from 'react';
import PaginationTable from './components/userTable';
import { useEffect, useState } from 'react';
import { call } from '../../utils/apiCall';
import SearchIcon from '@mui/icons-material/Search';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContentBox = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' }
}));

const H2 = styled('h2')(({ theme }) => ({
  fontSize: '24px',
  fontWeight: '600',
  color: '01052D',
  marginTop: '0px',
  marginBottom: '30px'
}));

const FooterManagement = () => {
  const { palette } = useTheme();
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [addUserModal, setAddUserModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [alertDeleteModal, setAlertDeleteModal] = useState(false);
  const [resetPassModal, setResetPassModal] = useState(false);
  const [totalItems, setTotalItems] = useState();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [skipData, setSkipData] = useState(0);
  const [searchKey, setSearchKey] = useState();
  const [userStatus, setUserStatus] = useState();
  const [sortData, setSortData] = useState('asc');

  useEffect(() => {
    getUserData();
  }, [searchKey, skipData, rowsPerPage, sortData, addUserModal]);

  const getUserData = () => {
    (async () => {
      try {
        const res = await call(
          'get',
          'api/v1/admin/footer-list',
          searchKey
            ? { search: searchKey, limit: rowsPerPage, skip: skipData, sort: sortData }
            : { limit: rowsPerPage, skip: skipData, sort: sortData },
          null
        );
        setUsersData(res.data.data ? res.data.data : []);
        setTotalItems(res.data.totalItems ? res.data.totalItems : 0);
        setLoading(false);
      } catch (err) {
        console.log(err);
        setUsersData([]);
      }
    })();
  };

  return (
    <Fragment>
      <ContentBox className="analytics">
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <H2>Footer Management</H2>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <div>
                {/* <Button
                  variant="contained"
                  size="small"
                  onClick={() => {setAddUserModal(!addUserModal)}}
                  sx={{ px: 3, py: 0.9, backgroundColor: '#0432A3' }}
                >
                  + Add Admin
                </Button> */}
              </div>
              <div style={{ marginRight: '20px' }}>
                <TextField
                  id="search"
                  type="search"
                  // label="Search"
                  placeholder="Search..."
                  size="small"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                  // sx={{ width: '50%' }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    )
                  }}
                />
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <PaginationTable
                data={usersData}
                alertModal={alertModal}
                setAlertModal={setAlertModal}
                alertDeleteModal={alertDeleteModal}
                setAlertDeleteModal={setAlertDeleteModal}
                addUserModal={addUserModal}
                setAddUserModal={setAddUserModal}
                resetPassModal={resetPassModal}
                setResetPassModal={setResetPassModal}
                totalItems={totalItems}
                page={page}
                setPage={setPage}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                setSkipData={setSkipData}
                userStatus={userStatus}
                setUserStatus={setUserStatus}
                sortData={sortData}
                setSortData={setSortData}
              />
            </div>
          </Grid>
        </Grid>
      </ContentBox>
      <ToastContainer />
    </Fragment>
  );
};

export default FooterManagement;
