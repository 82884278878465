import * as React from 'react';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { styled } from '@mui/material/styles';
import { useTheme, TextField, Grid } from '@mui/material';
import { useState } from 'react';
import { call } from '../../utils/apiCall';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';

const H2 = styled('h2')(({ theme }) => ({
    fontSize: '24px',
    fontWeight: '600',
    color: '01052D',
    marginTop: '0px',
    marginBottom: '30px'
}));


export default function EditFooter(props) {
    const { toggle } = props;
    const { palette } = useTheme();
    const textError = palette.error.main;
    const params = useParams()
    const [pageData, setPageData] = useState()
    const [title, setTitle] = useState("")
    const [titleError, setTitleError] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [subTitleError, setSubTitleError] = useState("")
    const [currentTitle, setCurrentTitle] = useState("")
    const [currentTitleError, setCurrentTitleError] = useState("")
    const [currentText, setCurrentText] = useState("")
    const [currentTextError, setCurrentTextError] = useState("")
    const [idsToDelete, setIdsToDelete] = useState([])
    const [selectedKey, setSelectedKey] = useState(0)
    const navigate = useNavigate()


    const handleSubmit = async () => {
        let formValid = true
        if (formValid) {
            let newData = []
            for (let i = 0; i < pageData.length; i++) {
                if (!pageData[i].id) {
                    pageData[i]["parent_id"] = params.id
                    newData.push(pageData[i])
                }
            }

            console.log(idsToDelete)
            console.log(newData)

            let bodyData = {
                forParam: "footer",
                mainId: parseInt(params.id),
                idsToDelete: idsToDelete,
                newData: newData
            }

            try {
                const res = await call(
                    'patch',
                    'api/v1/admin/edit-content',
                    {},
                    bodyData
                )

                if (res.data.success === true) {
                    navigate("/footer-management")
                    toast.success("Footer updated successfully.", { position: toast.POSITION.BOTTOM_LEFT })
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const removeOldData = (oldId, index) => {
        setIdsToDelete(prevData => {
            const updatedData = [...prevData];
            updatedData.push(oldId);
            return updatedData;
        });

        setPageData(prevData => {
            const updatedData = [...prevData];
            updatedData.splice(index, 1);
            return updatedData;
        })
    }

    const removeNewData = (index) => {
        setPageData(prevData => {
            const updatedData = [...prevData];
            updatedData.splice(index, 1);
            return updatedData;
        })
    }

    const handleNewData = () => {

        let formValid = true

        if (selectedKey === 0) {
            setCurrentTitleError("Key is required.")
            formValid = false
        }

        if (currentText === "") {
            setCurrentTextError("Title is required.")
            formValid = false
        }

        if (formValid) {


            let myObj = {
                body: null,
                key: selectedKey,
                title: currentText,
                link: null,
                subtitle: null,
                media: null,
                router_link: selectedKey,
            }

            setPageData(prevData => {
                const updatedData = [...prevData];
                updatedData.push(myObj);
                return updatedData;
            });
        }

    }

    const getPageData = () => {
        (async () => {
            try {
                const res = await call(
                    'get',
                    'api/v1/get-static-pages',
                    { title: params.key },
                    null
                );
                if (res.data.success) {
                    setTitle(res.data.data.title)
                    setSubTitle(res.data.data.subtitle)
                    setPageData(res.data.data.body)
                }

            } catch (err) {
                console.log(err);
                setPageData([]);
            }
        })();
    }

    useEffect(() => {
        getPageData()
    }, [])


    return (
        <form style={{ padding: "30px" }}>
            <h3 className="breadcumbTitle" style={{}}>
                <span className='bread-link' onClick={() => navigate("/footer-management")} onMouseEnter={(e) => e.target.style.color = '#2563a4'} onMouseLeave={(e) => e.target.style.color = 'black'}> Footer Management {'>'} </span>
                <span> Edit Footer </span>
            </h3>
            <H2>Edit Footer</H2>
            <Grid container spacing={3}>

                <Grid item>
                    <h2>Links</h2>
                    <Grid container spacing={1}>
                        {
                            pageData && pageData.length > 0 ?
                                pageData.map((ele, index) => {
                                    return (
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                                            <Grid item lg={5} md={6} sm={6} xs={12}>
                                                <FormControl
                                                    variant="outlined"
                                                    margin={"1"}
                                                    style={{ width: "100%" }}
                                                >
                                                    <InputLabel id="test-select-label">Key</InputLabel>
                                                    <Select
                                                        style={{ width: "100%" }}
                                                        variant="outlined"
                                                        value={ele.key}
                                                        disabled
                                                        labelId="test-select-label"
                                                        label={"Key"}
                                                    >
                                                        <MenuItem value={0}>
                                                            {"Select Key"}
                                                        </MenuItem>
                                                        <MenuItem value={"about"}>
                                                            {"about"}
                                                        </MenuItem>
                                                        <MenuItem value={"legal"}>
                                                            {"legal"}
                                                        </MenuItem>
                                                        <MenuItem value={"community"}>
                                                            {"community"}
                                                        </MenuItem>
                                                        <MenuItem value={"help"}>
                                                            {"help"}
                                                        </MenuItem>
                                                        <MenuItem value={"membership"}>
                                                            {"membership"}
                                                        </MenuItem>
                                                        <MenuItem value={"blog"}>
                                                            {"blog"}
                                                        </MenuItem>
                                                        <MenuItem value={"mobile"}>
                                                            {"mobile"}
                                                        </MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item lg={5} md={6} sm={6} xs={12}>
                                                <TextField
                                                    fullWidth
                                                    size="small"
                                                    type="text"
                                                    name="Title"
                                                    label="Title"
                                                    variant="outlined"
                                                    value={ele.title}
                                                    disabled
                                                    // onBlur={handleBlur}
                                                    sx={{ mb: 3, mt: 2 }}
                                                />
                                            </Grid>
                                            <Grid item lg={1} md={6} sm={6} xs={12}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ backgroundColor: '#0432A3' }}
                                                    onClick={() => ele.id ? removeOldData(ele.id, index) : removeNewData(index)}
                                                >
                                                    -
                                                </Button>
                                            </Grid>
                                        </div>
                                    )
                                })
                                :
                                <></>
                        }
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                            <Grid item lg={5} md={6} sm={6} xs={12}>
                                <FormControl
                                    variant="outlined"
                                    margin={"1"}
                                    style={{ width: "100%" }}
                                >
                                    <InputLabel id="test-select-label">Key</InputLabel>
                                    <Select
                                        style={{ width: "100%" }}
                                        variant="outlined"
                                        value={selectedKey}
                                        onChange={(e) => { setSelectedKey(e.target.value); setCurrentTitleError("") }}
                                        labelId="test-select-label"
                                        label={"Key"}
                                    >
                                        <MenuItem value={0}>
                                            {"Select Key"}
                                        </MenuItem>
                                        <MenuItem value={"about"}>
                                            {"about"}
                                        </MenuItem>
                                        <MenuItem value={"legal"}>
                                            {"legal"}
                                        </MenuItem>
                                        <MenuItem value={"community"}>
                                            {"community"}
                                        </MenuItem>
                                        <MenuItem value={"help"}>
                                            {"help"}
                                        </MenuItem>
                                        <MenuItem value={"membership"}>
                                            {"membership"}
                                        </MenuItem>
                                        <MenuItem value={"blog"}>
                                            {"blog"}
                                        </MenuItem>
                                        <MenuItem value={"mobile"}>
                                            {"mobile"}
                                        </MenuItem>

                                    </Select>
                                </FormControl>
                                {currentTitleError && (
                                    <Typography sx={{ color: textError }}>
                                        {currentTitleError}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item lg={5} md={6} sm={6} xs={12}>
                                <TextField
                                    fullWidth
                                    size="small"
                                    type="text"
                                    name="Title"
                                    label="Title"
                                    variant="outlined"
                                    value={currentText}
                                    onChange={(e) => setCurrentText(e.target.value)}
                                    onClick={() => setCurrentTextError("")}
                                    helperText={currentTextError}
                                    error={Boolean(currentTextError)}
                                    sx={{ mb: 3, mt: 2 }}
                                />
                            </Grid>
                            <Grid item lg={1} md={6} sm={6} xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleNewData()}
                                    sx={{ backgroundColor: '#0432A3' }}
                                >
                                    +
                                </Button>
                            </Grid>
                        </div>

                    </Grid>
                </Grid>

            </Grid>


            <Button
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
                sx={{ mt: 4, backgroundColor: '#0432A3', width: "160px" }}
            >
                Update Footer
            </Button>
        </form>
    );
}
