import * as React from 'react';

import { styled } from '@mui/material/styles';
import { useTheme, TextField, FormControlLabel, Checkbox } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { call } from '../../../utils/apiCall';
import 'react-phone-input-2/lib/material.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}
export default function AddUserModal(props) {
  const { modal, toggle, data, action } = props;
  const { palette } = useTheme();
  let textRegEx = /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/
  const textError = palette.error.main;
  const [question, setQuestion] = useState("")
  const [questionError, setQuestionError] = useState("")
  const [answerError, setAnswerError] = useState("")
  const [answer, setAnswer] = useState("")
  const [errorMessage, setErrorMessage] = useState('');
  const [title, setTitle] = useState("")
  const [titleError, setTitleError] = useState("")
  const [isPopular, setIsPopular] = useState(false)

  useEffect(() => {
    if (action == "view" || action == "edit") {
      setQuestion(data.question)
      setAnswer(data.answer)
      setTitle(data.heading)
      setIsPopular(data.is_popular)
    }
  }, [data, action])

  const handleSubmit = async () => {
    let formValid = true

    if (question === "") {
      formValid = false
      setQuestionError("Question is required.")
    } else if (!textRegEx.test(question)) {
      setQuestionError("Please enter valid question upto 50 characaters")
      formValid = false
    }

    if (answer === "") {
      formValid = false
      setAnswerError("Answer is required.")
    } else if (!textRegEx.test(answer)) {
      setAnswerError("Please enter valid answer upto 50 characaters")
      formValid = false
    }

    if (title === "" || !title) {
      formValid = false
      setTitleError("Title is required.")
    } else if (!textRegEx.test(title)) {
      setTitleError("Please enter valid title upto 50 characaters")
      formValid = false
    }

    if (formValid) {
      const bodyData = {
        question,
        answer,
        heading: title,
        is_popular: isPopular ? 1 : 0
      }

      try {
        const res = await call('post', 'api/v1/admin/add-faq', null, bodyData);
        toggle();
        toast.success('FAQ Created Successfully!', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }


  }

  const handleEdit = async () => {
    let formValid = true

    if (question === "") {
      formValid = false
      setQuestionError("Question is required.")
    } else if (!textRegEx.test(question)) {
      setQuestionError("Please enter valid question upto 50 characaters")
      formValid = false
    }

    if (answer === "") {
      formValid = false
      setAnswerError("Answer is required.")
    } else if (!textRegEx.test(answer)) {
      setAnswerError("Please enter valid answer upto 50 characaters")
      formValid = false
    }

    if (title === "" || !title) {
      formValid = false
      setTitleError("Title is required.")
    } else if (!textRegEx.test(title)) {
      setTitleError("Please enter valid title upto 50 characaters")
      formValid = false
    }

    if (formValid) {
      const bodyData = {
        id: data.id,
        question,
        answer,
        heading: title,
        is_popular: isPopular ? 1 : 0
      }

      try {
        const res = await call('patch', 'api/v1/admin/edit-faq', null, bodyData);
        toggle();
        toast.success('FAQ Updated Successfully!', {
          position: toast.POSITION.BOTTOM_LEFT
        });
      } catch (err) {
        console.log(err);
        setErrorMessage(err.response.data.message);
      }
    }
  }


  return (
    <div>
      <BootstrapDialog maxWidth={'lg'} aria-labelledby="customized-dialog-title" open={modal}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          {action === "add" ? "Add FAQ" : action == "view" ? "View FAQ" : "Edit FAQ"}
        </BootstrapDialogTitle>
        <DialogContent>
          <form style={{ padding: "30px" }}>
            <div style={{ display: "flex", flexDirection: "column", rowGap: 20 }}>
              <TextField
                style={{ width: "550px" }}
                value={question}
                onClick={() => {
                  setQuestionError('');
                  setErrorMessage('');
                }}
                onChange={(e) => setQuestion(e.target.value)}
                helperText={questionError}
                error={Boolean(questionError)}
                multiline
                label="Question"
                minRows={4}>
              </TextField>

              <TextField
                style={{ width: "550px" }}
                onClick={() => {
                  setAnswerError('');
                  setErrorMessage('');
                }}
                onChange={(e) => setAnswer(e.target.value)}
                helperText={answerError}
                error={Boolean(answerError)}
                value={answer}
                multiline
                label="Answer"
                margin='dense'
                minRows={9}
              >
              </TextField>

              <TextField
                style={{ width: "550px" }}
                onClick={() => {
                  setTitleError('');
                  setErrorMessage('');
                }}
                onChange={(e) => setTitle(e.target.value)}
                helperText={titleError}
                error={Boolean(titleError)}
                value={title}
                multiline
                label="Title"
                margin='dense'
              >
              </TextField>

              <FormControlLabel control={<Checkbox checked={isPopular} onChange={(e) => setIsPopular(e.target.checked)} />} label="Is Popular" />
            </div>

            <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
              <Button
                // fullWidth
                variant="contained"
                color="primary"
                // onClick={handleFormSubmit}
                // loading={loading}
                sx={{ mt: 4, backgroundColor: '#0432A3' }}
                onClick={() => { action == "add" ? handleSubmit() : handleEdit() }}
              >
                {action === "add" ? "Create FAQ" : "Update FAQ"}
              </Button>
              {errorMessage && (
                <Typography style={{ marginTop: '1rem' }} sx={{ color: textError }}>
                  {errorMessage}
                </Typography>
              )}

              <Button
                // fullWidth
                variant="contained"
                color="primary"
                // onClick={handleFormSubmit}
                // loading={loading}
                sx={{ mt: 4, px: 4, backgroundColor: '#0432A3', ml: 2 }}
                onClick={toggle}
              >
                Close
              </Button>
            </div>
          </form>
        </DialogContent>

      </BootstrapDialog>
    </div>
  );
}
