import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { useTheme, TextField, InputAdornment, Grid } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { call } from '../../../utils/apiCall';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2)
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1)
  }
}));

const styles = styled(TextField)({
  '*.Mui-focused': {
    borderColor: 'transparent',
    outline: 'none'
  }
});

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function PasswordResetModal(props) {
  const { modal, toggle, userId } = props;
  const { palette } = useTheme();
  const textError = palette.error.main;

  const [statusText, setStatusText] = useState("")
  const [error, setError] = useState("")

  const handleFormSubmit = async (event) => {

    let formValid = true

    if (statusText === "") {
      formValid = false
      setError("Please select status")
    }

    if (formValid) {
      let statusData = {
        status: statusText
      };
      // setLoading(true);
      try {
        const res = await call('patch', `api/v1/admin/update-support-status/${userId}`, null, statusData);
        toast.success('Status Updated Successful!', {
          position: toast.POSITION.BOTTOM_LEFT
        });
        toggle();
      } catch (err) {
        console.log(err);
      }
    }

  };

  return (
    <div>
      <BootstrapDialog maxWidth={'xs'} fullWidth aria-labelledby="customized-dialog-title" open={modal}>
        <BootstrapDialogTitle id="customized-dialog-title" onClose={toggle}>
          Update Status
        </BootstrapDialogTitle>
        <DialogContent>
          <form>
            <FormControl
              variant="outlined"
              margin={"1"}
              style={{ width: "100%", marginTop: 15 }}
            >
              <InputLabel id="test-select-label">Select Status</InputLabel>
              <Select
                style={{ width: "100%" }}
                variant="outlined"
                labelId="test-select-label"
                label={"Select Status"}
                value={statusText}
                onChange={(e) => setStatusText(e.target.value)}
              >
                <MenuItem value={"open"}>
                  {"Open"}
                </MenuItem>
                <MenuItem value={"pending"}>
                  {"Pending"}
                </MenuItem>
                <MenuItem value={"resolved"}>
                  {"Resolved"}
                </MenuItem>
              </Select>
            </FormControl>

            {error && (
              <Typography style={{ marginTop: '1rem' }} sx={{ color: textError }}>
                {error}
              </Typography>
            )}

            <Button
              fullWidth
              variant="contained"
              color="primary"
              onClick={() => handleFormSubmit()}
              sx={{ mt: 4, px: 10, backgroundColor: '#0432A3' }}
            >
              Update Status
            </Button>

          </form>
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={toggle}>
            Save changes
          </Button>
        </DialogActions> */}
      </BootstrapDialog>
    </div>
  );
}
